import React from "react";
import styles from "./styles.css";
import Testimonial from "./images/testimonial.png";
import LazyImage from "../../shared/lazy-image";
import Slider from "react-slick";
import TestimonialTwo from "./images/testimonial-2.png";
import TestimonialThree from "./images/testimonial-3.png";
import TestimonialFour from "./images/testimonial-4.png";
import TrackImpression from "../../shared/track-impression";
import { HOME_AMPLITUDE_EVENTS } from "../../../tracking/amplitude-events";
import { trackAmplitude } from "../../../tracking";

const CustomerTestimonials = () => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrow: false,
        dots: true
    };

    const handleImpressionView = () => {
        trackAmplitude(HOME_AMPLITUDE_EVENTS.REVIEW_SECTION_SEEN);
    };

    const handleIndexChange = () => {
        trackAmplitude(HOME_AMPLITUDE_EVENTS.REVIEW_SECTION_SCROLLED);
    };

    return (
        <TrackImpression  event={{name: "testimonialViewd"}} handleImpressionView={handleImpressionView}>
            <div styleName={"styles.wrapper"}>
                <h2 styleName={"styles.heading"}>Here&apos;s what our <strong>customers</strong> say about us</h2>
                <Slider {...settings} afterChange={handleIndexChange}>
                    <div styleName={"styles.contentOuter"}>
                        <div styleName={"styles.detailTextWrap"}>
                            <p styleName={"styles.description"}>
                        Had a really smooth buying experience. The car was delivered to me on a day/time I selected. One of the team took me through the car,
                        explained all the features, hooked up my phone to Bluetooth and even offered to do a first drive with me.
                                <br /><br />
                        The car was exactly as they described it and showed it in the photos on their site. Despite being a used car it was spotless and had that ‘new car smell’.
                        Warranty was included and the price included all the on road costs. The whole process was super easy.<br /><br />I would recommend CARS24 to everyone!
                            </p>
                            <p styleName={"styles.customerName"}>ALICK,<br />Melbourne</p>
                        </div>
                        <div styleName={"styles.imageWrapper"}>
                            <LazyImage src={Testimonial} alt="Here’s what customers say about us" />
                            <div styleName={"styles.blurBg"}>&nbsp;</div>
                        </div>
                    </div>
                    <div styleName={"styles.contentOuter"}>
                        <div styleName={"styles.detailTextWrap"}>
                            <p styleName={"styles.description"}>
                        I cannot rave about this company enough. I would love to give more than 5
                         stars as you deserve it. Where do I begin? My husband and I recently
                          relocated from New Zealand to the Gold Coast. Choosing a car was a
                           hard decision. We found an awesome Mazda at CARS24.
                                <br /><br />
                           I was very reluctant paying upfront without seeing the vehicle.
                         Ordered the vehicle effortlessly online. Two days later the vehicle
                          arrived. It looked exactly like the photos. Customer service from
                           start to finish was outstanding.
                            </p>
                            <p styleName={"styles.customerName"}>ANGELA,<br />Gold Coast</p>
                        </div>
                        <div styleName={"styles.imageWrapper"}>
                            <LazyImage src={TestimonialTwo} alt="Here’s what customers say about us" />
                            <div styleName={"styles.blurBg"}>&nbsp;</div>
                        </div>
                    </div>
                    <div styleName={"styles.contentOuter"}>
                        <div styleName={"styles.detailTextWrap"}>
                            <p styleName={"styles.description"}>
                        Easy process and friendly team. I received prompt answers to my questions
                         and a smooth delivery. The car was exactly as described and I had peace
                          of mind with the Carcierge service, warranty and roadside assist. I
                           love my new car, thank you to the whole team. As a woman, it was a great experience to buy a car, unlike some of the car experiences that I've had in the past.
                            </p>
                            <p styleName={"styles.customerName"}>JULIE,<br />Sunshine Coast</p>
                        </div>
                        <div styleName={"styles.imageWrapper"}>
                            <LazyImage src={TestimonialThree} alt="Here’s what customers say about us" />
                            <div styleName={"styles.blurBg"}>&nbsp;</div>
                        </div>
                    </div>
                    <div styleName={"styles.contentOuter"}>
                        <div styleName={"styles.detailTextWrap"}>
                            <p styleName={"styles.description"}>
                        We were looking for a fairly priced, good quality used car and we are absolutely delighted with the car we bought from CARS24! It was like new and exactly what it said on the website! The whole process was so easy too, with no annoying sales tactics.<br /><br />I was really impressed especially with the trade-in experience with no one trying to point out issues with my car and pushing down prices, compared to other people and companies I've tried to sell my car to. Overall, excellent value and great experience, thanks for the great work!
                            </p>
                            <p styleName={"styles.customerName"}>CHARMAINE & MARK,<br />Sydney</p>
                        </div>
                        <div styleName={"styles.imageWrapper"}>
                            <LazyImage src={TestimonialFour} alt="Here’s what customers say about us" />
                            <div styleName={"styles.blurBg"}>&nbsp;</div>
                        </div>
                    </div>
                </Slider>
            </div>
        </TrackImpression>
    );
};

export default CustomerTestimonials;
