import React, { useState} from "react";
import styles from "./styles.css";
import Slider from "react-slick";
import Testimonial1 from "./images/Testimonial_1.png";
import Testimonial2 from "./images/Testimonial_2.png";
import { trackAmplitude, trackMobileCustomEventsAU } from "../../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../../tracking/au.mobile-events";
import { AMPLITUDE_EVENTS_CATEGORY } from "../../../../tracking/amplitude-events";

const settings = {
    slidesToShow: 1.1,
    slidesToScroll: 1,
    infinite: false,
    arrow: false,
    dots: false,
    useTransform: false,
    focusOnSelect: true
};

const reviews = [
    {
        icon: Testimonial1,
        title: "Janelle, Melbourne",
        heading: "Great protection",
        description: "I could definitely tell the difference in the paint, it's a lot shinier and the water just beads off the vehicle when it rains. The exterior protection is doing its job, I have not had to wash the vehicle once. For someone who drives a vehicle often for work doing long hours normally I would have dirt and dust on the vehicle however this is not the case."
    },
    {
        icon: Testimonial2,
        title: "Danielle, Brisbane",
        heading: "Fantastic package",
        description: "I am very happy with the Protect Max bundle and how it makes the car look. I would definitely reccommend this package to friends and family as I have noticed that I dont have to maintain the car as much - the exterior still looks shiny and clean since delivery."
    }
];

const CustomerTestimonials = () => {
    const [showMoreIds, setShowMoreIds] = useState([]);

    const onReadMoreClick = (e, i) => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.PRE_BC_CAR_CARE_PACKS_LANDING_PAGE, {
            eventLabel: "Yes",
            eventAction: "Read more_testimonial"
        });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.LANDING_PAGE, {
            "Read more": "Read_more"
        });
        if (showMoreIds.includes(i)) {
            setShowMoreIds(prev => prev.filter(c => c !== i));
        } else {
            setShowMoreIds(prev => [...prev, i]);
        }
    };

    return (
        <div styleName={"styles.testimonialOuter"}>
            <p styleName={"styles.testimonialHeading"}>Don't just take our word for it</p>
            <Slider {...settings}>
                {reviews.map((review, i) => {
                    const { description, heading, icon, title } = review;
                    const showMore = showMoreIds.includes(i);

                    return (
                        <div key={i} styleName={"styles.testinomialCardWrap"}>
                            <div styleName={"styles.cardTopSection"}>
                                <img src={icon} />
                                <div styleName={"styles.gradient"}>&nbsp;</div>
                            </div>
                            <div styleName={"styles.contentWrap"}>
                                <p styleName={"styles.customerName"}>{title}</p>
                                <p styleName={"styles.customerExperience"}>{heading}</p>
                                <p className="review" styleName={`styles.customerReview ${showMore ? "styles.expanded" : ""}`}>{description}</p>
                                <p onClick={e => onReadMoreClick(e, i)} styleName={"styles.readMore"}>{showMore ? "Read less" : "Read more"}</p>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default CustomerTestimonials;
