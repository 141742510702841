import React, { useState } from "react";
import PropTypes from "prop-types";
import VizSensor from "react-visibility-sensor";
import { trackAmplitude, trackDesktopCustomEventsAU } from "../../../tracking";

const TrackImpression = ({ children, event = {}, handleImpressionView = () => {}, wrapWithDiv = true}) => {
    const { name = "", data = {}, amplitudeName = "", properties = {}, gaName = "" } = event;
    const [isViewed, setIsViewed] = useState(false);

    const onChangeCardVisibility = (isVisible) => {
        if (isVisible && !isViewed) {
            trackDesktopCustomEventsAU(name || gaName, data);
            if (amplitudeName) trackAmplitude(amplitudeName, properties);
            setIsViewed(true);
            if (name)trackAmplitude(name, data);
            handleImpressionView();
        }
    };

    const childComponent =  (children || []).length ?
        React.Children.map(children, child => {
            return child ? React.cloneElement(child) : null;
        }) :
        children;

    return (
        <VizSensor partialVisibility onChange={onChangeCardVisibility}>
            {wrapWithDiv ? <div>{childComponent}</div> : <React.Fragment>{childComponent}</React.Fragment> }
        </VizSensor>
    );
};

TrackImpression.propTypes = {
    children: PropTypes.object,
    event: PropTypes.object,
    handleImpressionView: PropTypes.func,
    wrapWithDiv: PropTypes.bool
};

export default TrackImpression;
